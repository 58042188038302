<template>
  <div class="mt-2">
    <b-container>
      <div class="mb-5 mt-4">
        <b-row class="d-flex justify-content-between">
          <b-col
            md=""
            sm="12"
            class="rounded px-2 py-1 mb-1 mb-sm-0"
            :class="[hoveredCol1 ? 'bg-colorGreen' : 'bg-colorBlue']"
            @mouseover="hoveredCol1 = true"
            @mouseleave="hoveredCol1 = false"
          >
            <div class="pb-1 h-25">
              <h4 class="font-weight-bolder text-light">
                Air Tickets Services
              </h4>
            </div>
            <div class="pt-1">
              <ul class="text-light">
                <li>Booking and Confirmation</li>
                <li>Ticket Delivery</li>
                <li>Waitlisted Ticket Management</li>
                <li>Ticket Collection and Reissuance</li>
              </ul>
            </div>
          </b-col>
          <b-col
            md=""
            sm="12"
            class="rounded px-2 py-1 m-0 ml-md-50 mb-1 mb-sm-0"
            :class="[hoveredCol2 ? 'bg-colorGreen' : 'bg-colorBlue']"
            @mouseover="hoveredCol2 = true"
            @mouseleave="hoveredCol2 = false"
          >
            <div class="pb-1 h-25">
              <h4 class="font-weight-bolder text-light">
                International & Domestic Hotel Reservations
              </h4>
            </div>
            <div class="pt-1">
              <ul class="text-light">
                <li>Reservations in 150 Countries</li>
                <li>Hotel Tours and Cruises</li>
                <li>Local and Overseas Services</li>
              </ul>
            </div>
          </b-col>
          <b-col
            md=""
            sm="12"
            class="rounded px-2 py-1 m-0 ml-md-50 mb-1 mb-sm-0"
            :class="[hoveredCol5 ? 'bg-colorGreen' : 'bg-colorBlue']"
            @mouseover="hoveredCol5 = true"
            @mouseleave="hoveredCol5 = false"
          >
            <div class="pb-1 h-25">
              <h4 class="font-weight-bolder text-light">Visa Services</h4>
            </div>
            <div class="pt-1">
              <ul class="text-light">
                <li>Visa Assistance for Various Countries</li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </div></b-container
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      hoveredCol1: false,
      hoveredCol2: false,
      hoveredCol3: false,
      hoveredCol4: false,
      hoveredCol5: false,
      hoveredCol6: false,
      hoveredCol7: false,
      hoveredCol8: false,
    };
  },
};
</script>

<style></style>
